export const tRight = "text-right";
export const tCenter = "text-center";
export const tLeft = "text-left";

export const SavingsAndInvestment = '../../images/SPHERE/Savings And Investment.png';
export const Protection = '../../images/SPHERE/Protection.png';
export const Health = '../../images/SPHERE/Health.png';
export const Education = '../../images/SPHERE/Education.png';
export const Retirement = '../../images/SPHERE/Retirement.png';
export const EstatePlanning = '../../images/SPHERE/Estate Planning.png';

export const WebFNAMain = "#WebFNAMain";
export const MiniLoaderNcss = "#miniloader";
export const MiniLoaderNcss0 = "#miniloader-1";

export const locations = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola",
    "Anguilla", "Antartica", "Antigua & Barbuda", "Antilles", "Argentina",
    "Armenia", "Aruba", "Australia", "Austria", "Azerbaijan",
    "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus",
    "Belgium", "Belize", "Benin", "Bermuda", "Bhutan", "Bolivia",
    "Bosnia", "Botswana", "Brazil", "Brunei", "Bulgaria",
    "Burkina Faso", "Burundi", "Cambodia", "Cameroon", "Canada",
    "Canary Islands", "Cape Verde", "Cayman Islands", "Central African Rep",
    "Chad", "Chile", "China", "Colombia", "Comoros", "Congo", "Costa Rica",
    "Cote d'Ivoire", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark",
    "Djibouti", "Dominica", "Dominican Republic", "East Timor",
    "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea",
    "Eritrea", "Estonia", "Ethiopia", "Federated States of Micronesia",
    "Fiji", "Finland", "France", "French Guiana", "Gabon", "Georgia",
    "Germany", "Ghana", "Gibraltar", "Greece", "Greenland", "Grenada",
    "Guam", "Guatemala", "Guinea", "Guinea Bissau", "Guyana", "Haiti", "Holland",
    "Honduras", "Hongkong", "Hungary", "Iceland", "India", "India (other than Jammu and Kashmir)",
    "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy", "Ivory Coast", "Jamaica",
    "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Kosovo", "Kuwait", "Kyrgyz Republic",
    "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania",
    "Luxembourg", "Macao", "Macedonia", "Madagascar", "Madeira", "Malawi", "Malaysia",
    "Maldives", "Mali", "Malta", "Marshall Islands", "Martinique", "Mauritania",
    "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia",
    "Montenegro", "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru",
    "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria",
    "North Korea", "Norway", "Oman", "Other", "Pakistan", "Palau", "Panama",
    "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal",
    "Puerto Rico", "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis",
    "Saint Lucia", "Saipan", "Samoa", "San Marino", "Sao Tome and Principe",
    "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone",
    "Singapore", "Slovak Republic", "Slovakia", "Slovenia", "Solomon Islands",
    "Somalia", "South Africa", "South Korea", "Spain", "Sri Lanka", "Sudan",
    "Suriname", "Swaziland", "Sweden", "Switzerland", "Syria", "Taiwan",
    "Tajikistan", "Tanzania", "Thailand", "The Bahamas", "The Gambia",
    "Togo", "Tonga", "Trinidad", "Trinidad and Tobago", "Tunisia",
    "Turkey", "Turkmenistan", "Tuvalu", "UNITED STATES OF AMERICA",
    "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom",
    "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City",
    "Venezuela", "Vietnam", "Yemen", "Zaire", "Zambia", "Zimbabwe"];
export const provinces = [
    "Abra", "Agusan Del Norte", "Agusan Del Sur", "Aklan", "Albay",
    "Antique", "Apayao", "Aurora", "Basilan", "Bataan", "Batanes",
    "Batangas", "Benguet", "Biliran", "Bohol", "Bukidnon", "Bulacan",
    "Cagayan", "Camarines Norte", "Camarines Sur", "Camiguin", "Capiz",
    "Catanduanes", "Cavite", "Cebu", "Compostela Valley", "Cotabato",
    "Davao Del Norte", "Davao Del Sur", "Davao Occidental", "Davao Oriental",
    "Dinagat Islands", "Eastern Samar", "Guimaras", "Ifugao", "Ilocos Norte",
    "Ilocos Sur", "Iloilo", "Isabela", "Kalinga", "La Union", "Laguna",
    "Lanao Del Norte", "Lanao Del Sur", "Leyte", "Maguindanao", "Marinduque",
    "Masbate", "Metro Manila", "Misamis Occidental", "Misamis Oriental",
    "Mountain Province", "Negros Occidental", "Negros Oriental", "Northern Samar",
    "Nueva Ecija", "Nueva Vizcaya", "Occidental Mindoro", "Oriental Mindoro",
    "Palawan", "Pampanga", "Pangasinan", "Quezon", "Quirino", "Rizal", "Romblon",
    "Samar", "Sarangani", "Siquijor", "Sorsogon", "South Cotabato", "Southern Leyte",
    "Sultan Kudarat", "Sulu", "Surigao del Norte", "Surigao del Sur", "Tarlac",
    "Tawi-Tawi", "Zambales", "Zamboanga Sibugay", "Zamboanga del Norte", "Zamboanga del Sur"];
export const cities = [
    "Caloocan", "Las Pinas", "Makati", "Malabon", "Mandaluyong", "Manila",
    "Marikina", "Muntinlupa", "Navotas", "Paranaque", "Pasay", "Pasig", "Pateros", "Quezon City",
    "San Juan", "Taguig", "Valenzuela"];